function handleAccordion(event) {
    event.preventDefault();

    let $this = $(event.currentTarget),
        $accordionBody = $this.closest('.accordion').find('.accordion_body'),
        duration = 200;

    if ($this.hasClass('accordion_head')) {
        $this = $this.find('.accordion_button');
    }

    /* Function to animate height: auto */
    function autoHeightAnimate(element, time){
        let curHeight = element.height(), // Get Default Height
            autoHeight = element.css('height', 'auto').height(); // Get Auto Height
        element.height(curHeight); // Reset to Default Height
        element.stop().animate({ height: autoHeight }, time, 'linear'); // Animate to Auto Height
    }

    if ($this.hasClass('accordion_button--close')) {
        // close accordion
        $accordionBody.stop().animate({ height: '0' }, duration, 'linear');
        $this.removeClass('accordion_button--close');
    } else {
        // open accordion
        if ($('.accordion_button--close').closest('.accordion').find('.accordion_body').length) {
            $('.accordion_button').removeClass('accordion_button--close');
            $('.accordion_button').closest('.accordion').find('.accordion_body').stop().animate({ height: '0' }, duration, 'linear');
        }
        $this.addClass('accordion_button--close');
        autoHeightAnimate($accordionBody, duration);

        trackPageView($this.closest('.accordion').find('.accordion_head').text());
    }
}

function scrollToAccordion() {
    const hash = location.hash;
    const target = $('.accordion[data-faq="'+hash.slice(1)+'"]');
    const offset = $('.header-nav').innerHeight() - 30;

    if (target.hasClass('accordion')) {
        setTimeout(function() {
            target.find('.accordion_head').trigger('click');
        }, 100);
    }

    if (target.length) {
        $('html,body').animate({
            scrollTop: target.offset().top - offset
        }, 1000);
        return false;
    }
}

export { handleAccordion };

export default function () {
    $('.accordion_head').click(handleAccordion);
    if(location.hash) scrollToAccordion();
}