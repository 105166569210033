import {handleAccordion} from "../components/accordion";

const $productGridSelector = $(".product-grid:not('.disabled-js')");
const $header = $('.header');
const $spinner = $('<div class="spinner spinner--product" style="display: none"></div>');
const DEFAULT_DOCUMENT_TITLE = document.title;

let numberOfCols, numberOfRows;

let AJAX_PRODUCT = false;

function ajaxFetchProductDetail($productThumb) { 
    let url = $productThumb.attr('data-href');
    let $productGrid = $productThumb.closest($productGridSelector);
    let gridRow = $productThumb.attr('data-grid-row');
    let elementAtTheEndOfRow = $productGrid.find(`.product-grid-item[data-grid-row='${gridRow}']`).last();
    const productLineURL = $('.js-product-detail_btn').length ? $('.js-product-detail_btn').attr('href') : $productGrid.attr('data-route');

    AJAX_PRODUCT = true;

    axios.post(url)
        .then(function (response) {
            let $tmpProductDetail = $(response.data.html).first();
            let $existingProductDetail = $productGridSelector.find('.product-detail');

            // if($existingProductDetail.length) {
            //     $existingProductDetail.animate({
            //         opacity: 0,
            //         height: 'hide'
            //     }, 600, () => {
            //         $existingProductDetail.remove();
            //
            //         $tmpProductDetail.insertAfter(elementAtTheEndOfRow).hide().css('grid-row', parseInt(gridRow) + 1);
            //
            //         $productGridSelector.find('.spinner').animate({
            //             opacity: 0,
            //             height: 'hide'
            //         }, 600);
            //
            //         $tmpProductDetail.animate({
            //             opacity: 1,
            //             height: 'show'
            //         }, 600);
            //
            //         $('html, body').stop(true, true).animate({ scrollTop: $productThumb.offset().top - $header.height() - 20 }, 600, function() {
            //             AJAX_PRODUCT = false;
            //         });
            //
            //         $spinner.remove();
            //     });
            // } else {
                $tmpProductDetail.insertAfter(elementAtTheEndOfRow).hide().css('grid-row', parseInt(gridRow) + 1);

                $productGridSelector.find('.spinner').animate({
                    opacity: 0,
                    height: 'hide'
                }, 600);

                $tmpProductDetail.animate({
                    opacity: 1,
                    height: 'show'
                }, 600);

                $('html, body').stop(true, true).animate({scrollTop: $productThumb.offset().top - $header.height() - 20}, 600, function () {
                    AJAX_PRODUCT = false;
                });

                $spinner.remove();
                // });
            // }

            document.title = `${response.data.title} | ${DEFAULT_DOCUMENT_TITLE}`;
            
            if (productLineURL) {
                if (history.state && history.state.productRow) {
                    window.history.replaceState({
                        productRow: $productThumb.attr('data-grid-row'),
                        productCol: $productThumb.attr('data-grid-col')
                    }, response.data.title, `${productLineURL}/${response.data.slug}`);
                } else {
                    window.history.pushState({
                        productRow: $productThumb.attr('data-grid-row'),
                        productCol: $productThumb.attr('data-grid-col')
                    }, response.data.title, `${productLineURL}/${response.data.slug}`);
                }
            }
        })
        .catch(function (error) {
            console.error(error);
        });
}

function ajaxLoadProduct($productThumb) {
    if ($productThumb.hasClass('product-grid-item--active') || AJAX_PRODUCT) {
        return;
    }

    let $productGrid = $productThumb.closest($productGridSelector);
    let gridRow = $productThumb.attr('data-grid-row');
    let elementAtTheEndOfRow = $productGrid.find(`.product-grid-item[data-grid-row='${gridRow}']`).last();

    let $existingProductDetail = $productGridSelector.find('.product-detail');

    $productGridSelector.find('.product-grid-item').removeClass('product-grid-item--active');
    $productThumb.addClass('product-grid-item--active');

    if($existingProductDetail.length) {
        $existingProductDetail.animate({
            opacity: 0,
            height: 'hide'
        }, 600, () => {
            $existingProductDetail.remove();

            $spinner.insertAfter(elementAtTheEndOfRow).css('grid-row', parseInt(gridRow) + 1).animate({
                opacity: 1,
                height: 'show'
            }, 600);

            ajaxFetchProductDetail($productThumb);
        });
    } else {
        $spinner.insertAfter(elementAtTheEndOfRow).css('grid-row', parseInt(gridRow) + 1).animate({
            opacity: 1,
            height: 'show'
        }, 600);

        ajaxFetchProductDetail($productThumb);
    }
}

function getNumberOfColumns($productGrid, $gridContainerItems) {
    let spacing;

    if ($gridContainerItems.outerWidth(true) - $gridContainerItems.outerWidth() > 0) {
        spacing = $gridContainerItems.outerWidth(true) - $gridContainerItems.outerWidth();
    } else {
        spacing = 15;
    }

    numberOfCols = Math.floor($productGrid.width() / ($gridContainerItems.outerWidth() + spacing));

    return numberOfCols;
}

function recalculateRowAndCols() {
    $productGridSelector.each((index, element) => {
        let $productGrid = $(element);
        let $gridContainerItems = $productGrid.find('.product-grid-item');
        let numberOfColumns = getNumberOfColumns($productGrid, $gridContainerItems);
        let row = 0;

        $gridContainerItems.each((index, element) => {
            let $this = $(element);

            if (index % numberOfColumns === 0) {
                row++;
            }

            $this.attr('data-grid-row', row);
            $this.attr('data-grid-col', index % numberOfColumns + 1);
        });
    });
}

function initGrid() {
    if (!$productGridSelector.length) {
        return;
    }

    recalculateRowAndCols();

    $productGridSelector.on('click', '.product-grid-item', (event) => {
        event.preventDefault();
        ajaxLoadProduct($(event.currentTarget));
    });

    $productGridSelector.on('click', '.product-detail_close', (event) => {
        let $existingProductDetail = $(event.currentTarget).closest('.product-detail');
        $(event.currentTarget).closest($productGridSelector).find('.product-grid-item').removeClass('product-grid-item--active');
        $existingProductDetail.animate({
            opacity: 0,
            height: 'hide'
        }, 600, () => {
            // document.title = DEFAULT_DOCUMENT_TITLE;
            window.history.back();
            $existingProductDetail.remove();
        });
    });
    // });

    if ($('.product-grid-item.product-grid-item--active').length) {
        let $tmpProductThumb = $('.product-grid-item--active');
        $tmpProductThumb.removeClass('product-grid-item--active').trigger( "click" );
    }

    $('.js-product-detail_btn').click((event) => {
        event.preventDefault();
        let $this = $(event.currentTarget);
        $('html, body').stop(true, true).animate({ scrollTop: $productGridSelector.offset().top - $header.height() - 20 }, 600);

        trackPageView($this.text());
    });

    // TODO: rework this a little, make loading and switching between states more elegant
    window.onpopstate = function(event) {
        if (event.state && event.state.productRow) {
            if ($productGridSelector.find(`.product-grid-item[data-grid-row='${event.state.productRow}'][data-grid-col='${event.state.productCol}']`).length) {
                ajaxLoadProduct($productGridSelector.find(`.product-grid-item[data-grid-row='${event.state.productRow}'][data-grid-col='${event.state.productCol}']`));
            }
        } else {
            if($productGridSelector.find('.product-detail').length) {
                $productGridSelector.find('.product-grid-item').removeClass('product-grid-item--active');
                $productGridSelector.find('.product-detail').animate({
                    opacity: 0,
                    height: 'hide'
                }, 600, () => {
                    document.title = DEFAULT_DOCUMENT_TITLE;
                    $productGridSelector.find('.product-detail').remove();
                });
            }
        }
    };
}

function initTooltips() {
    $('.js-product-tooltip_button').on('click', (event) => {
        let $this = $(event.currentTarget);

        $this.closest('.product-tooltip').siblings('.product-tooltip--is-open').removeClass('product-tooltip--is-open');
        $this.closest('.product-tooltip').toggleClass('product-tooltip--is-open');

        let tooltipClass = $this.closest('.product-tooltip').hasClass('product-tooltip--top') ? 'product-tooltip--top' : 'product-tooltip--bottom';
        trackPageView(tooltipClass);
    });
}

function initAccordions() {
    $productGridSelector.on('click', '.accordion_button', handleAccordion);
}

export { initGrid, initTooltips, initAccordions };

$(function () {
    initGrid();
    initTooltips();
    initAccordions();

    window.addEventListener('resize', (event) => {
        initGrid();
    });

    $('.js-scroll-down').click(e => {
        e.preventDefault();
        let $this = $(e.currentTarget);
        let $target = $($this.attr('href'));
        let $header = $('.header');

        if ($target.length) {
            $('html, body').stop(true, true).animate({ scrollTop: $target.offset().top - $header.height() - 20 }, 600);
        }
    });
});
